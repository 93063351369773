// src/components/ListQuotations.js
import React, { useEffect, useState } from 'react';
import './ListQuotations.css'; // Možeš dodati stilove za ponude
import Header from './Header'; // Uvezi komponentu Header
import { generateInvoiceQuotationImage, generateInvoiceQuotationNumber, 
   calculateTotalAmount, getInvoiceQuotationData } from './utils/invoiceUtils'; // Ažuriraj putanju prema tvojoj strukturi
import InvoicePreviewModal from './InvoicePreviewModal';
import { useNavigate } from 'react-router-dom';
import axiosInstance from './utils/axiosInstance';
import { FaEnvelope } from 'react-icons/fa'; // Import email icon
import { Tooltip } from 'react-tooltip'; // Import Tooltip


const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000';

const ListQuotations = ({ selectedName, onLogout }) => {
  const [quotations, setQuotations] = useState([]);
  const [imageUrl, setImageURL] = useState(null);
  const [selectedInvoiceQuotation, setSelectedInvoiceQuotation] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const fetchQuotations = async () => {
    try {
  
      const response = await axiosInstance.get(`${API_URL}/quotations`, {
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json',},
      });
  
      setQuotations(response.data.reverse());
    } catch (error) {
      console.error('Error fetching quotations:', error);
      alert('Došlo je do greške u dohvaćanju ponuda');

    }
  };
  

  useEffect(() => {
    fetchQuotations(); // Initial fetch when component mounts
  }, []);

  const navigate = useNavigate();

  const handleLogout = () => {
    onLogout();
    navigate('/');
  };

  const handleChangeUser = () => {
    selectedName = '';
    navigate('/names');
  };

  const openQuotationPreview = async (quotation) => {
    setImageURL(await generateInvoiceQuotationImage(quotation.quotationNumber, false)); // Generiraj URL slike za ponudu
    setSelectedInvoiceQuotation({ ...quotation, imageUrl }); // Dodaj URL slike u odabranu ponudu
    setIsModalOpen(true); // Otvori modal
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedInvoiceQuotation(null);
    fetchQuotations(); // Osvježi popis ponuda
  };

  const handleClickCreateInvoiceFromQuotation = async(quotation) =>{
    const newInvoiceNo = await handleCreateInvoiceFromQuotation(quotation);
    if (newInvoiceNo) {
      const imageUrl = await generateInvoiceQuotationImage(newInvoiceNo, true); // Generiraj sliku računa
      if (imageUrl) {
        setSelectedInvoiceQuotation(await getInvoiceQuotationData(newInvoiceNo, true)); // spremi podatke o računu
        setImageURL(imageUrl); // Postavi URL slike
        setIsModalOpen(true); // Otvori modal
      }
    }
  }

  const handleCreateInvoiceFromQuotation = async (quotation) => {
    try {
      const newInvoiceNumber = await generateInvoiceQuotationNumber(true);
  
      const invoiceData = {
        invoiceNumber: newInvoiceNumber,
        operator: quotation.operator, // Ime operatera
        date: new Date(), // Datum i vrijeme
        customer: quotation.customer, // ID odabranog kupca
        services: quotation.services.map(service => ({
          serviceId: service.serviceId, // ID usluge
          quantity: service.quantity, // Količina
          price: service.price, // Cijena
          unit: service.unit // Jedinica
        })),
        totalAmount: calculateTotalAmount(quotation.services), // Izračunaj ukupni iznos
        quotationReference: quotation._id
      };
  
  
      // Spremi račun u bazu
      const response = await axiosInstance.post(`${API_URL}/invoices`, invoiceData, {
        headers: {
          'Content-Type': 'application/json', // Osiguranje ispravnog tipa sadržaja
        },
        withCredentials: true,
      });
  
      console.log('Račun je uspješno kreiran', response.data);
      setSelectedInvoiceQuotation(invoiceData);
  
      return newInvoiceNumber;    
      // Ovdje možeš dodati dodatne radnje nakon uspješnog kreiranja računa, npr. resetiranje forme
    } catch (error) {
      console.error('Došlo je do greške prilikom kreiranja računa:', error);
      alert('Došlo je do greške u kreiranju računa');
      return null;

    }
  };

  const handlePaymentStatusToggle = async (quotation) => {
    try {
      const updatedQuotation = await axiosInstance.put(
        `${API_URL}/quotations/update-payment-status/${quotation._id}`,
        { isPaid: !quotation.isPaid },
        {
          headers: {
            'Content-Type': 'application/json',
          },
          withCredentials: true,
        }
      );

      setQuotations((prevQuotations) =>
        prevQuotations.map((q) =>
          q._id === quotation._id ? { ...q, isPaid: !quotation.isPaid } : q
        )
      );
    } catch (error) {
      console.error('Error updating payment status:', error);
      alert('Došlo je do greške prilikom ažuriranja statusa plaćanja');
    }
  };

  return (
    <div>
      <Header 
        selectedName={selectedName} 
        handleLogout={handleLogout} 
        handleChangeUser={handleChangeUser}
      />
      <div className='table-container2'>
        <table className='quotation-table'>
          <thead>
            <tr>
              <th className='quotation-number'>Ponuda Broj</th>
              <th className='operator'>Operater</th>
              <th className='client'>Klijent</th>
              <th className='date'>Datum</th>
              <th className='total'>Ukupno</th>
              <th className='action'>Akcija</th>
            </tr>
          </thead>
          <tbody>
            {quotations.map((quotation) => (
              <tr key={quotation._id}>
                <td className='quotation-number'>{quotation.quotationNumber}</td>
                <td className='operator'>{quotation.operator}</td>
                <td className='client'>
                  {quotation.customer.name}
                  {quotation.emailHistory.length > 0 && (
                    <>
                      <FaEnvelope 
                        className='email-icon' 
                        data-tooltip-id={`emailHistory-${quotation._id}`} 
                      />
                      <Tooltip id={`emailHistory-${quotation._id}`} place="top" effect="solid">
                        {quotation.emailHistory.map((entry, index) => (
                          <div key={index}>
                            <p>{new Date(entry.sentAt).toLocaleString()} {entry.email}</p>
                          </div>
                        ))}
                      </Tooltip>
                    </>
                  )}
                </td>
                <td className='date'>
                  {new Date(quotation.date).toLocaleDateString('hr-HR', {
                    weekday: 'long',
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric'
                  })}
                  <br />
                  {new Date(quotation.date).toLocaleTimeString()}
                </td>
                <td className='total'>
                  {quotation.totalAmount.toFixed(2)} €
                  <span
                    className={quotation.isPaid ? 'paid' : 'unpaid'}
                    onClick={() => handlePaymentStatusToggle(quotation)}
                    style={{ cursor: 'pointer' }}
                  >
                    {quotation.isPaid ? ' ✔️' : ' ❌'}
                  </span>
                </td>
                <td className='action'>
                  <button 
                    className='preview-button' 
                    onClick={() => openQuotationPreview(quotation)}>Pregled
                  </button>
                  <button 
                    className='create-invoice-button2' 
                    onClick={() => handleClickCreateInvoiceFromQuotation(quotation)}
                    >Izradi račun
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Modal za pregled ponude */}
      {selectedInvoiceQuotation && (
        <InvoicePreviewModal 
          isOpen={isModalOpen} 
          onRequestClose={closeModal} 
          invoiceImageUrl={imageUrl} 
          invoiceData={selectedInvoiceQuotation}
        />
      )}
    </div>
  );
};

export default ListQuotations;
